export default {
  IS_COMPANY_ADMIN:
    'This account type allows you to edit your company profile, view positions created by other ' +
    'users at your company, and create and edit positions for your company.',
  IS_NOT_COMPANY_ADMIN:
    'This account type allows you to view your company profile, view positions' +
    ' created by other users at your company, and create and edit positions for your company.',
  ACCOUNT_ADMINISTRATOR:
    "This person has access to the main company's account, including the ability to request " +
    'additional user seats, update the main company profile and create/manage individual opportunity ' +
    'postings (internships, jobs and events).',
  COMPANY_DESCRIPTION_PREVIEW: 'Keep this short and sweet!',
  COMPANY_DESCRIPTION: 'Less is more. The ideal length of this section is 2-3 paragraphs. Keep this short and sweet!',
  INDUSTRY_VERTICALS:
    "Students and recent graduates are able to search for companies using HIVE's Industry Verticals. " +
    'We recommend you select only up to a few.',
  INTERNSHIPS_AND_JOB_FUNCTIONS:
    'Students and recent graduates are able to search for companies using this list of job functions' +
    ' associated with your overall positions - not just those currently posted.',
  INTERNAL_DIVISION:
    'Use this field if you have multiple business areas or internal divisions ' +
    'within your organization that are based on region and/or sector.',
  SUBSIDIARIES: 'Use this field if you have additional companies underneath your registered ' + 'parent corporation.',
  COMPANY_IN_FIVE_EMOJIS:
    'Next-gen alert! Our students and recent grads love this, and their profiles include these too!' +
    ' For Windows - Click your windows key and semicolon at the same time For Mac - Click command, ' +
    'control and space bar at the same time.',
  OUR_CULTURE:
    'Be Yourself! This option helps our students and recent graduates get to know you even better. ' +
    'You may provide a picture or video of teams/people, offices, or other ways to show your ' +
    "company's culture.",
  MISSION_STATEMENT:
    'Who are you as a company? What are your business and cultural goals? Less is more and you ' +
    "can link to your website as well if you'd like.",
  CULTURAL_KEYWORDS:
    'Students and recent graduates are able to search for companies using this list of cultural ' +
    'and career keywords associated with your company.',
  SOCIAL_IMPACT_AREAS_OF_FOCUS:
    'What do you care about? Give examples of where your company is involved across community-focused' +
    ' and social impact issues.',
  NEXT_GENERATION_OF_TALENT_STATEMENT:
    'This is a short statement to ensure our students and recent graduates feel valued and a ' +
    'priority for your company.',
  PRIMARY_WELCOME_FROM_LEADERSHIP:
    'Include a welcome message from your company here that students and recent grads can use to' +
    ' get to know you better. If a video, 30 seconds max with a caption, and include a caption and' +
    ' descriptive text with a picture.',
  MEET_OUR_TEAM:
    'These are very impactful! Our students and recent graduates LOVE hearing from different ' +
    'people in your organization. We encourage you to have one person per category, and to change ' +
    "these out as often as you'd like. Please include team members across all levels - Executives, " +
    'DEI, Talent/Recruiting, Recent Grads and Current Interns.',
  OUR_FAVORITE_INFLUENCERS:
    'Who do you follow? Share your favorite influencers with  our community of next generation talent.',
  OPPORTUNITY_FOR:
    'Name of internal or regional division if different than primary HIVE account or parent company' +
    " subsidiary's name if parent company is primary HIVE account.",
  OPPORTUNITY_TYPE:
    'Many positions fall within the categories of Event, Job, or Internship. ' +
    'Some positions, such as summits, should be input as Other Positions.',
  COMPENSATION: "If you'd like to include only one amount, input the same salary for both parts of the range.",
  DEADLINE: 'You will be able to extend your deadline if need be once you have posted it.',
  PROCESS:
    'All internal notifications, communications and candidate applications will be sent to the' +
    ' email address associated with this account, which is not currently visible to any candidates.',
  EXTERNAL_LINK:
    "HIVE's candidate dashboard will allow for you to filter through, organize and send messages" +
    ' to candidates. In addition to this function, if there is a requisite external application link' +
    ' please include that here. Please do NOT include this link anywhere else.',
  APPLICATION_LINK:
    "Note here if you'd like for all applicants to apply via the external link or if you plan to " +
    'review candidates first before inviting them to apply.',
  ATS_SYSTEM: 'Please input here which ATS system your company will use for this specific position.',
  PROFILE_PICTURES: 'Candidates are given the option to include a profile picture on their digital application.',
  OPPORTUNITY_OPEN_TO: 'Please contact b@hellohive.com if you have any questions about this.',
  OPPORTUNITY_CLASS_UNDERGRADUATE: 'HIVE Class Standing is based on the new Academic Calendar starting August 15th.',
  OPPORTUNITY_CLASS_MASTER:
    'HIVE Class Standing for Master’s degree students is calculated based on if they have a summer remaining prior to their graduation.',
  ADDITIONAL_PREFERRED_QUALIFICATIONS:
    'Please include additional recommended, but not required, qualifications and skills for this ' +
    'position. You can reference preferred academic areas of study (specific majors) along with any' +
    " of the other previously-mentioned filters available on HIVE's candidate dashboard.",
  DATA_INSIGHT_OTHER: 'Use this filter to see HIVE members who have selected “Other” as a Diversity.',
  DATA_INSIGHTS_DIVERSITIES:
    'Use this filter to see HIVE members who have self-identified and submitted any information about their diverse backgrounds and experiences.',
  GRANT_ADMIN_ACCESS:
    'Any user with admin access will be able to edit this Position, as well as view and manage applicants.',
};

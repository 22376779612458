import { Row } from '@hivediversity/common-lib/base';
import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import {
  applicantPropTypes,
  getDiversityName,
  getFormatPhone,
  getMajorName,
  isGraduated,
  opportunityProps,
  locationsOptions,
} from '@hivediversity/common-lib/utils';
import { getLocation, push } from 'connected-react-router';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import { contactTagEntities, resumeOrigin, routes } from 'constant';
import { getEmployer } from 'store/employer/selectors';
import { getSavingTag } from 'store/opportunity/selectors';
import { getStaticData } from 'store/static-data/selectors';
import { getMonthName, getStudentFirstAndLastName, transformRoute } from 'utils';

import Notes from './notes/index';
import {
  Anchor,
  ArrowContainer,
  ArrowDown,
  CardContainer,
  CardInformation,
  ContactTag,
  ContactTagDescriptionContainer,
  Hexagon,
  Image,
  List,
  Star,
  SubTitle,
  Telephone,
  Title,
  Wrapper,
  WrapperButtons,
  WrapperTitle,
} from './styled';
import Tags from './tags';

const getSelectedContactTagName = (contactTags = [], tag) =>
  contactTags.find(contactTag => contactTag.id === tag)?.contacted || 'Contact Candidate';

const Card = ({ applicant, entity, opportunity, showContactTags, onShowContactTags, origin }) => {
  const dispatch = useDispatch();
  const employer = useSelector(getEmployer);
  const majorName = getMajorName(applicant.student);
  const diversities = applicant.student.diversity?.filter(
    diversity => diversity?.added === 1 && diversity?.includeOnResume
  );
  const location = useSelector(getLocation);
  const { contactTags, months } = useSelector(getStaticData);
  const savingContactTag = useSelector(getSavingTag);
  const applicantStudentCityOfHomeName = applicant.student.cityOfHomeName
    ? `${applicant.student.cityOfHomeName}, `
    : '';
  const applicantStudentInternationalHome = `${
    applicant.student.internationalHome ? applicant.student.countryOfHomeName : applicant.student.stateOfHome
  }`;
  const applicantStudentAddress = `${applicantStudentCityOfHomeName}${applicantStudentInternationalHome}`;

  const canEdit = employer.companyAdmin || opportunity.createdBy.id === employer.id;

  return (
    <CardContainer
      id={applicant.id}
      background="linear-gradient(135deg, rgba(29,37,38,1) 0%, rgba(29,37,38,1) 15%, rgba(0,0,0,1) 100%), #1D2526"
    >
      {applicant.viewed && <Star />}

      {canEdit && <Notes aplicantId={applicant.id} aplicantNotes={applicant.notes} />}

      {applicant.student.thumbUrl && opportunity.profilePictures ? (
        <Image src={applicant.student.thumbUrl} />
      ) : (
        <Hexagon>{`${first(applicant.student.firstName)}${first(applicant.student.lastName)}`}</Hexagon>
      )}

      {canEdit && (
        <Tags applicantId={applicant.id} currentTag={applicant.tag} opportunityId={opportunity.id} entity={entity} />
      )}

      <Wrapper>
        <WrapperTitle>
          <Title>{`${getStudentFirstAndLastName(applicant)}.`}</Title>
          {applicant.student.monthGraduation && applicant.student.yearGraduation && (
            <SubTitle>{`${isGraduated(
              applicant.student.monthGraduation,
              applicant.student.yearGraduation
            )}: ${getMonthName(months, applicant.student.monthGraduation)} ${applicant.student.yearGraduation} ${
              applicant.student.gpa && applicant.student?.includeGPAOnResume ? `| GPA: ${applicant.student.gpa}` : ''
            }`}</SubTitle>
          )}
        </WrapperTitle>
        <CardInformation>
          <List>
            {(applicant.student.phone || applicant.student.email) && (
              <Telephone>
                📱 {` ${getFormatPhone(applicant.student.phone)}`} | 📧{' '}
                <Anchor href={`mailto:${applicant.student.email}`}>{applicant.student.email}</Anchor>
              </Telephone>
            )}
            {applicant.student.universityName && (
              <li>
                <strong>{applicant.student.universityName}</strong>
                <br />
                {applicant.student.typeName ? `${applicant.student.typeName} |` : ''}
                {applicant.student.cityOfUniversityName &&
                  applicant.student.stateOfUniversity &&
                  ` ${applicant.student.cityOfUniversityName}, ${applicant.student.stateOfUniversity}`}
              </li>
            )}
            {majorName && (
              <li>
                <strong>Degree & Academic Info: </strong>
                {majorName}
              </li>
            )}
            {applicant.student.stateOfHome && applicant.student.stateOfHomeName && (
              <li>
                <strong>Hometown: </strong>
                {`${applicantStudentAddress}`}
              </li>
            )}
            {applicant.student.myselfEmoji && (
              <li>
                <strong>Me in 5 Emojis: </strong>
                <span role="img" aria-label="Emojis">
                  {applicant.student.myselfEmoji}
                </span>
              </li>
            )}
            {!isEmpty(diversities) && applicant.student.includeDiversityInclusionsResume && (
              <li>
                <strong>My Diversities: </strong>
                {diversities.map(diversity => `${getDiversityName(diversity)}`).join(', ')}
              </li>
            )}
            {opportunity.locations.length > 1 && (
              <li>
                <strong>Applied Location(s): </strong>
                {locationsOptions(applicant.opportunityLocations)
                  .map(locationOption => locationOption.name)
                  .join(' / ')}
              </li>
            )}
          </List>
          <WrapperButtons>
            <CustomButton
              color={colors.VIOLET}
              $bsStyle={{ maxWidth: '124px', padding: '0 10px' }}
              onClick={() =>
                dispatch(
                  push({
                    pathname: `${transformRoute(routes.RESUME, {
                      opportunityId: opportunity.id,
                      applicantId: applicant.id,
                    })}?origin=${origin}`,
                  })
                )
              }
            >
              Resume
            </CustomButton>
            <CustomButton
              color={colors.VIOLET}
              $bsStyle={{ maxWidth: '124px', padding: '0 10px' }}
              onClick={() =>
                dispatch(
                  push({
                    pathname: transformRoute(routes.PROFILE, {
                      opportunityId: opportunity.id,
                      applicantId: applicant.id,
                    }),
                    state: {
                      previous: location.pathname,
                      viewDiversities: opportunity.diversities,
                      viewProfilePicture: opportunity.profilePictures,
                      prioritizedBullets: opportunity.prioritizedBullets,
                    },
                  })
                )
              }
            >
              Profile
            </CustomButton>
          </WrapperButtons>
        </CardInformation>
      </Wrapper>
      {canEdit && (
        <ContactTag onClick={() => onShowContactTags(!showContactTags)}>
          <ContactTagDescriptionContainer>
            {savingContactTag === applicant.id ? (
              <Loader type="TailSpin" color="white" height={24} width={24} />
            ) : (
              <>
                <Row center $bsStyle={{ marginLeft: '40px' }}>
                  {getSelectedContactTagName(contactTags, applicant.contactTag)}
                </Row>
                <ArrowContainer>
                  <ArrowDown showContactTags={showContactTags} />
                </ArrowContainer>
              </>
            )}
          </ContactTagDescriptionContainer>
        </ContactTag>
      )}
    </CardContainer>
  );
};

Card.propTypes = {
  onShowContactTags: PropTypes.func.isRequired,
  applicant: applicantPropTypes.isRequired,
  opportunity: opportunityProps.isRequired,
  entity: PropTypes.oneOf([
    contactTagEntities.OPPORTUNITIES,
    contactTagEntities.OPPORTUNITY,
    contactTagEntities.OPPORTUNITIES_CREATED_BY_ME,
  ]).isRequired,
  showContactTags: PropTypes.bool.isRequired,
  origin: PropTypes.oneOf([resumeOrigin.OPPORTUNITIES, resumeOrigin.OPPORTUNITY]).isRequired,
};

export default Card;

import { Row } from '@hivediversity/common-lib/base';
import { Collapsible } from '@hivediversity/common-lib/components';
import { opportunitySection, opportunityTypes, yesNo } from '@hivediversity/common-lib/constant';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Checkboxes,
  Dropdown,
  Modal,
  MultiSelect,
  RadioButtons,
  Tooltip,
  WrapTextBox,
  ClickHereLabel,
} from 'components';
import {
  applicantsFilter,
  applicationsReceived,
  placeholders,
  registrantsFilter,
  tooltipsTexts,
  opportunityAdministrationModals,
  opportunityAdministrationModalsEntities,
} from 'constant';
import companyDataCreator from 'store/company/actions';
import { getCompany, getUsers } from 'store/company/selectors';
import { getEmployer } from 'store/employer/selectors';
import { getStaticData } from 'store/static-data/selectors';
import { Text } from 'styled';

import { ExternalApplicationContainer, Title, QuestionContainer, SectionLabel, ResumeImage } from './styled';

const initialModalsState = {
  profilePictures: false,
  diversities: false,
  whyCompany: false,
  prioritizedBullets: false,
};

const Administration = ({ values, open, onCollapse, isValid, setFieldValue, showValidateIcon }) => {
  const ref = useRef(null);
  const { atsSystem } = useSelector(getStaticData);
  const [showModals, setShowModals] = useState(initialModalsState);
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const users = useSelector(getUsers);
  const employer = useSelector(getEmployer);

  useEffect(() => {
    if (values.grantAdminAccess) {
      dispatch(companyDataCreator.companyFetchUsersRequest());
    }
  }, []);

  const handleShowModal = id => setShowModals(previousState => ({ ...previousState, [id]: !previousState[id] }));

  const handleClickOutside = event => {
    if (ref.current && !event.target.contains(ref.current)) {
      setShowModals(initialModalsState);
    }
  };

  const handleGrantAdminAccess = (name, value) => {
    setFieldValue(name, value);
    if (value) {
      dispatch(companyDataCreator.companyFetchUsersRequest());
    } else {
      setFieldValue('adminUsers', []);
    }
  };

  const onHasExternalApplicationLinkChange = useCallback(
    (name, value) => {
      if (!value) {
        setFieldValue('applicantsFilter', null);
      }

      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <Collapsible
      id={opportunitySection.ADMINISTRATION}
      onCollapse={onCollapse}
      title={values.type === opportunityTypes.EVENT ? 'Event Registration Process' : 'Candidate Application Process'}
      isValid={isValid}
      open={open}
      showValidateIcon={showValidateIcon}
    >
      <Modal
        $bsStyle={{ maxWidth: 'initial', padding: 0 }}
        show={Object.values(showModals).some(modal => modal)}
        onClose={() => setShowModals(initialModalsState)}
        closeButtonStyle={{ top: '5px', right: '10px' }}
      >
        <>
          {opportunityAdministrationModals.map(modal => (
            <Fragment key={modal.key}>{showModals[modal.key] && <ResumeImage ref={ref} Img={modal.image} />}</Fragment>
          ))}
        </>
      </Modal>
      <QuestionContainer>
        <Row $bsStyle={{ flexWrap: 'wrap', width: '100%' }}>
          <Title>If selected candidates have questions, please provide the preferred contact email address. *</Title>
          <Tooltip text={tooltipsTexts.PROCESS} />
        </Row>
        <WrapTextBox
          wrapperContainerStyle={{ maxWidth: '300px' }}
          name="preferredContactEmail"
          placeholder="Preferred Contact Email"
        />
      </QuestionContainer>
      {values.type && (
        <QuestionContainer>
          <Row $bsStyle={{ flexWrap: 'wrap', width: '100%' }}>
            <Title>{`${
              values.type === opportunityTypes.EVENT ? 'Event Registration Link' : 'External Application Link?'
            } *`}</Title>
            <Tooltip text={tooltipsTexts.EXTERNAL_LINK} />
          </Row>
          {!values.type ||
            ([opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(values.type) && (
              <RadioButtons
                name="hasExternalApplicationLink"
                value={values.hasExternalApplicationLink}
                options={yesNo}
                setFieldValue={onHasExternalApplicationLinkChange}
                isHorizontal
              />
            ))}
          {(([opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(values.type) &&
            values.hasExternalApplicationLink) ||
            values.type === opportunityTypes.EVENT) && (
            <ExternalApplicationContainer>
              <WrapTextBox
                name="applicationUrl"
                placeholder={placeholders.URL_START_WITH_HTTP}
                $bsStyle={{ minWidth: '100px', maxWidth: '300px', marginBottom: '20px' }}
              />
              <Row>
                <Text>{values.type === opportunityTypes.EVENT ? 'Registrants' : 'Applicants'} *</Text>
                <Tooltip text={tooltipsTexts.APPLICATION_LINK} />
              </Row>
              <RadioButtons
                name="applicantsFilter"
                value={values.applicantsFilter}
                options={values.type === opportunityTypes.EVENT ? registrantsFilter : applicantsFilter}
                setFieldValue={setFieldValue}
                takeDefaultLabel
                isHorizontal
                $bsStyle={{
                  marginBottom: [opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(values.type) ? '10px' : 0,
                }}
              />
              <>
                <Text>
                  Please share any additional instructions for applicants who are applying via external application
                  link:
                </Text>
                <WrapTextBox
                  name="additionalInformation"
                  placeholder="What information do applicants need in order to successfully apply though this external application link?"
                  rows="10"
                  maxLength={5000}
                  component="textarea"
                  $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
                />
              </>
            </ExternalApplicationContainer>
          )}
          {[opportunityTypes.JOB, opportunityTypes.INTERNSHIPS].includes(values.type) && (
            <QuestionContainer $bsStyle={{ marginTop: '30px', marginBottom: 0 }}>
              <Text>
                Please share any interview guidance for applicants here. These will be sent to candidates when you
                invite them to interview to if they ley us know that they&apos;ve been invited to interview.
              </Text>
              <WrapTextBox
                name="interviewTips"
                rows="10"
                maxLength={5000}
                component="textarea"
                $bsStyle={{ marginBottom: '16px', overflowY: 'scroll', height: '100%' }}
              />
            </QuestionContainer>
          )}
        </QuestionContainer>
      )}
      <QuestionContainer marginBottom="35px">
        <Row bsStyle={{ flexWrap: 'wrap' }}>
          <Title>ATS System</Title>
          <Tooltip text={tooltipsTexts.ATS_SYSTEM} />
        </Row>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <Dropdown
            name="atsSystemId"
            onChange={setFieldValue}
            value={values.atsSystemId}
            placeholder="Select ATS System"
            options={atsSystem}
            $bsStyle={{
              padding: 0,
              minWidth: '90px',
              textAlign: 'center',
              maxWidth: '300px',
            }}
            showIndicatorSeparator
          />
          {values.atsSystemId === 41 && (
            <WrapTextBox
              name="atsSystem"
              placeholder="Write ATS system"
              $bsStyle={{ maxWidth: '300px', marginLeft: '10px' }}
            />
          )}
        </Row>
      </QuestionContainer>
      <QuestionContainer marginBottom="30px" $bsStyle={{ maxWidth: '900px' }}>
        <Title>{`${
          values.type === opportunityTypes.EVENT ? 'Registration' : 'Candidate Package'
        } Delivery Preferences *`}</Title>
        <Checkboxes
          isHorizontal
          $bsStyle={{ justifyContent: 'space-between', flex: 1 }}
          name="applicationsReceived"
          options={applicationsReceived}
          values={values}
        />
      </QuestionContainer>
      {(!values.id || (values.id && values.createdBy?.id === employer?.id)) && (
        <>
          <QuestionContainer>
            <Row>
              <Title>{`Do you want to grant admin access to this position and applicants to anyone else at ${company.name}?`}</Title>
              <Tooltip text={tooltipsTexts.GRANT_ADMIN_ACCESS} position="left" />
            </Row>
            <RadioButtons
              name="grantAdminAccess"
              value={values.grantAdminAccess}
              options={yesNo}
              setFieldValue={handleGrantAdminAccess}
              isHorizontal
            />
          </QuestionContainer>
          {values.grantAdminAccess && (
            <QuestionContainer>
              <Title $bsStyle={{ fontSize: '19px', fontStyle: 'italic' }}>
                By granting another user admin privileges for this position, I understand that two or more users cannot
                edit an position at the same time.
              </Title>
              <Title $bsStyle={{ fontSize: '17px' }}>Add admin</Title>
              <MultiSelect
                name="adminUsers"
                placeholder="Select a user"
                options={users}
                values={values.adminUsers}
                setFieldValue={setFieldValue}
                showIndicatorSeparator
                getOptionLabel={option => option.username}
                isArray
              />
            </QuestionContainer>
          )}
        </>
      )}
      <SectionLabel>Hive innovations section</SectionLabel>
      <QuestionContainer>
        <Row>
          <Title>Profile Pictures on Digital Application?</Title>
          <Tooltip text={tooltipsTexts.PROFILE_PICTURES} />
        </Row>
        <ClickHereLabel
          onClick={() => handleShowModal(opportunityAdministrationModalsEntities.PROFILE_PICTURES)}
          label="to see HIVE’s Digital Application with Profile Picture visible."
        />
        <RadioButtons
          name="profilePictures"
          value={values.profilePictures}
          options={yesNo}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>View Diversities?</Title>
        <ClickHereLabel
          onClick={() => handleShowModal(opportunityAdministrationModalsEntities.DIVERSITIES)}
          label="to see a sample of HIVE’s resume with a candidate’s My Diversities section included and
            explained."
        />
        <RadioButtons
          name="diversities"
          value={values.diversities}
          options={yesNo}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>Require Why Company Statement?</Title>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <ClickHereLabel onClick={() => handleShowModal(opportunityAdministrationModalsEntities.WHY_COMPANY)}>
            Click here
          </ClickHereLabel>
          <Text>to see a sample of HIVE’s resume with the Why Company Statement highlighted and explained.</Text>
        </Row>
        <RadioButtons
          name="whyOurCompany"
          value={values.whyOurCompany}
          options={yesNo}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>Require Transcript (provided as link)</Title>
        <RadioButtons
          name="hasTranscript"
          value={values.hasTranscript}
          options={yesNo}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>Require Portfolio (provided as link)</Title>
        <RadioButtons
          name="hasPortfolio"
          value={values.hasPortfolio}
          options={yesNo}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </QuestionContainer>
      <QuestionContainer>
        <Title>Show Prioritized Bullets (Buzzwords)</Title>
        <Row $bsStyle={{ flexWrap: 'wrap' }}>
          <ClickHereLabel onClick={() => handleShowModal(opportunityAdministrationModalsEntities.PRIORITIZED_BULLETS)}>
            Click here
          </ClickHereLabel>
          <Text>to see a sample.</Text>
        </Row>
        <RadioButtons
          name="prioritizedBullets"
          value={values.prioritizedBullets}
          options={yesNo}
          setFieldValue={setFieldValue}
          isHorizontal
        />
      </QuestionContainer>
    </Collapsible>
  );
};

Administration.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: opportunityProps.isRequired,
  open: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  showValidateIcon: PropTypes.bool,
};

Administration.defaultProps = {
  showValidateIcon: false,
};

export default Administration;
